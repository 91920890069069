import React from "react";
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function AddLinkButton(props) {
    return (
        <IconButton 
            aria-label="delete"
            onClick={props.onClick}
        >
            <AddCircleIcon />
        </IconButton>
    );
}

export default AddLinkButton;