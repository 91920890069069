import React from "react";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

function GenerateButton(props) {
    return (
        <div className="generateButton">
            <Button
                variant="contained"
                onClick={props.onClick}
                endIcon={<SendIcon />}
            >
                Generate
            </Button>
        </div>
    );
}

export default GenerateButton;