import React from "react";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function GitLink(props) {
    const defaultLink = "https://github.com/{owner}/{repository}"
    const defaultBranch = "master"

    return (
        <div className="flex-container">
            <TextField
                required
                error={props.linkError}

                helperText={props.linkError ? "GitHub link format is incorrect." : ""}
                sx={{ m: 1.5, width: '40vw' }}
                label="Git Link"
                placeholder={defaultLink}
                value={props.link}
                onChange={(e) => props.onChangeLink(props.repo.id, e.target.value)}
            />
            
            <TextField
                required
                error={props.branchError}

                helperText={props.branchError ? "Branch must be non-empty." : ""}
                sx={{ m: 1.5, width: '10vw' }}
                label="Branch"
                placeholder={defaultBranch}
                value={props.branch}
                onChange={(e) => props.onChangeBranch(props.repo.id, e.target.value)}
            />

            <div className="parentElement">
                {
                    props.index !== 0 ?
                        <div className="centered-element">
                            <IconButton 
                                aria-label="delete" 
                                size="medium"
                                onClick={() => props.onDelete(props.repo.id)}
                                sx={{ padding: 0, margin: 4 }}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    : <br/>
                }
            </div>
            
        </div>
    );
}

export default GitLink;