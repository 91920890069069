import axios from 'axios';

export const fetchGeneration = (data, setSnackbarMessage) => {
    setSnackbarMessage({
        message: "Generating Diagram. This can take up to a minute.",
        type: "info",
        timeout: 20000
    })

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    axios.post(API_ENDPOINT, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 120000
    })
      .then((response) => {
        // console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'diagram.svg'); // or extract the filename from content-disposition
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setSnackbarMessage({
            message: "Diagram generated! Check your downloads folder.",
            type: "success",
            timeout: 8000
        })
      })
      .catch((error) => {
        setSnackbarMessage({
            message: "Failed to generate diagram. Please reach out if the problem persists.",
            type: "error",
            timeout: 8000
        })
      });
  };

const regex = /https:\/\/github\.com\/([^\/]+)\/([^\/]+)/;

export const checkLink = (repo) => {
    if (repo.link.match(regex)) {
      return true
    } else {
      return false
    }
  }

export const checkBranch = (repo) => {
    if (repo.branch.length > 0) {
      return true
    } else {
      return false
    }
  }

export const formatLinkBranch = (repos) => {
    return repos.map((repo) => {
      const match = repo.link.match(regex);

      const owner = match[1];
      const repository = match[2];
      const branch = repo.branch;

      return {
        owner: owner,
        repository: repository,
        branch: branch,
      }
    });
  }