import React, { useState } from 'react';
import { v4 as uuid } from "uuid";
import '../css/App.css';

import GitLink from './GitLink';
import Box from '@mui/material/Box';
import GenerateButton from './GenerateButton';
import Footer from './Footer';
import AddLinkButton from './AddLinkButton';
import AlertMessage from './AlertMessage'

import { ThemeProvider, createMuiTheme } from '@mui/material/styles';

import { fetchGeneration, checkLink, checkBranch, formatLinkBranch } from '../util/util';
import AboutButton from './AboutButton';

function HomePage() {
  const [repos, setRepos] = useState([
    {
      id: uuid(),
      link: "https://github.com/sachinreddy1/YetiBackend",
      branch: "main",
      linkError: false,
      branchError: false
    }
  ])

  const theme = createMuiTheme({
    typography: {
      fontSize: 12, // Adjust the font size as per your preference
    },
  });

  // --------------------

  const addRepo = () => {
    const newList = repos.concat(
      {
        id: uuid(),
        link: "",
        branch: "",
        linkError: false,
        branchError: false,
      }
    )
    setRepos(newList);
  }

  const updateRepoLink = (id, newLink) => {
    setRepos((prevRepos) => {
      return prevRepos.map((repo) => {
        if (repo.id === id) {
          return { ...repo, link: newLink };
        }
        return repo;
      });
    });
  };

  const updateRepoBranch = (id, newBranch) => {
    setRepos((prevRepos) => {
      return prevRepos.map((repo) => {
        if (repo.id === id) {
          return { ...repo, branch: newBranch };
        }
        return repo;
      });
    });
  };

  const deleteRepo = (id) => {
    const newList = repos.filter((repo) => repo.id !== id);
    setRepos(newList);
  }

  const [snackbarMessage, setSnackbarMessage] = React.useState(null);

  function handleCloseSnackbar(event, reason) {
      if (reason === "clickaway") {
          return;
      }
      setSnackbarMessage(null);
  }

  // --------------------

  const generateDiagram = () => {
    var allReposValid = true

    setRepos((prevRepos) => {
      return prevRepos.map((repo) => {
        const linkValid = checkLink(repo);
        const branchValid = checkBranch(repo);

        allReposValid = (allReposValid && linkValid && branchValid)

        return { 
          ...repo, 
          linkError: !linkValid,
          branchError: !branchValid,
        };
      });
    });
    
    if (allReposValid) {
      const data = formatLinkBranch(repos)
      console.log(data)
      fetchGeneration(data, setSnackbarMessage)
    }
  }

  // --------------------  

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <AboutButton/>

          <AlertMessage
            open={snackbarMessage !== null}
            message={snackbarMessage !== null ? snackbarMessage.message : ""}
            messageType={snackbarMessage !== null ? snackbarMessage.type : ""}
            timeout={snackbarMessage !== null ? snackbarMessage.timeout : 0}
            handleClose={handleCloseSnackbar}
          />

          <Box sx={{ m: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {repos.map((repo, index) =>
              <GitLink
                index={index}

                repo={repo}
                link={repo.link}
                branch={repo.branch}

                linkError={repo.linkError}
                branchError={repo.branchError}

                onDelete={deleteRepo}
                onChangeLink={updateRepoLink}
                onChangeBranch={updateRepoBranch}
              />
            )};
          </Box>

          {
            repos.length <= 5 ?
            <AddLinkButton 
              onClick={addRepo}
            /> :
            ""
          }
          
          <GenerateButton
            onClick={generateDiagram}
          />
          <Footer/>
        </header>
      </div>
    </ThemeProvider>
  );
}

export default HomePage;
