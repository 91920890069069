import React from "react";
import Footer from './Footer';
import BackButton from "./BackButton";

function AboutPage() {
    return (
        <div className="App">
            <header className="App-header">
                <BackButton/>

                <div className="aboutText">
                    Paste your public GitHub repository links and generate a flowchart.
                </div>
                <br/>
                <div className="aboutText">
                    This project currently only supports Python, Java and Kotlin files.
                </div>
                <br/>
                <div className="aboutText">
                    Since we are using Anthropic's Claude 3 Opus LLM, the max context window is 200K. This limits how much code we can generate flowcharts for.
                </div>
                <br/>
                <div className="aboutText">
                    Stay tuned! Better LLMs will be available in the future, allowing for more detailed and larger flowcharts.
                </div>
                <br/>

                <img className="diagram" src="https://s3.amazonaws.com/sachinreddy.com/diagram.svg" alt="Diagram" />

                <Footer/>
            </header>
        </div>
    )
}

export default AboutPage;