import React from "react";
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';

function Footer() {
  return (
    <div className="footer">
      <IconButton 
          aria-label="website" 
          size="medium"
          onClick={() => window.open('https://www.sachinreddy.com', '_blank')}
          sx={{ padding: 0, margin: 1, color: 'whitesmoke' }}
      >
          <LanguageIcon fontSize="inherit" />
      </IconButton>

      <div className="footerText">v0.1</div>
    </div>
  );
}

export default Footer;
