import React from "react";
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

function AboutButton() {
    return (
      <div className="aboutButton">
        <IconButton 
            aria-label="website" 
            size="medium"
            onClick={() => window.location.href = '/about'}
            sx={{ color: '#1976d2' }}
        >
            <InfoIcon fontSize="inherit" />
        </IconButton>  
      </div>
    );
  }
  
  export default AboutButton;
  