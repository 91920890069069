import React from "react";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackButton() {
    return (
      <div className="aboutButton">
        <IconButton 
            aria-label="website" 
            size="medium"
            onClick={() => window.location.href = '/'}
            sx={{ color: '#1976d2' }}
        >
            <ArrowBackIcon fontSize="inherit" />
        </IconButton>  
      </div>
    );
  }
  
  export default BackButton;
  