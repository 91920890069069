import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide from '@mui/material/Slide';


function AlertMessage(props) {
    return (
        <div>
            <Snackbar 
                open={props.open}
                autoHideDuration={props.timeout}
                onClose={props.handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={SlideTransition}
            >
                <Alert
                    onClose={props.handleClose}
                    severity={props.messageType}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    >
                    { props.message }
                </Alert>
            </Snackbar>
        </div>
    );
}

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}  

export default AlertMessage;